import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://80f7ad41f1ea4e430dcad294e80881c3@o4507152997810176.ingest.de.sentry.io/4508290090860624",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()